<template>
    <div class="M2-M3-jjhj">
        <div class="M2-M3-content">

            <div class="M2-M3-jjhj-chart">
                <div class="M2-M3-jjhj-title">财务因素</div>
                <div class="M2-M3-jjhj-title" style="font-size:14px;margin-top:10px;">1.财务质量</div>
                <div class="M2-M3-jjhj-title" style="font-size:14px;margin-top:10px;">1.1  资产质量和流动性</div>

                <m-slider :choseIndex="slider23" :setIndex="23"></m-slider>
    
                <div style="margin-top:40px;margin-bottom:20px;">
                    <el-input
                        type="textarea"
                        :rows="5"
                        placeholder="（请分析师对该指标信用评分进行解释说明）"
                        v-model="textarea">
                    </el-input>
                </div> 

                <div class="M2-M3-jjhj-title" style="font-size:14px">1.2  财务弹性</div>

                <m-slider :choseIndex="slider24" :setIndex="24"></m-slider>
    
                <div style="margin-top:40px;margin-bottom:20px;">
                    <el-input
                        type="textarea"
                        :rows="5"
                        placeholder="（请分析师对该指标信用评分进行解释说明）"
                        v-model="textarea2">
                    </el-input>
                </div> 
                
                <div class="M2-M3-jjhj-title" style="font-size:14px">1.3  整体现金流状况</div>

                <m-slider :choseIndex="slider25" :setIndex="25"></m-slider>
    
                <div style="margin-top:40px;margin-bottom:20px;">
                    <el-input
                        type="textarea"
                        :rows="5"
                        placeholder="（请分析师对该指标信用评分进行解释说明）"
                        v-model="textarea3">
                    </el-input>
                </div> 
            </div>

            <div class="M2-M3-jjhj-Edit">
                <div class="M2-M3-jjhj-title">财务指标</div> 

                <div class="M2-M3-jjhj-title" style="font-size:14px;margin-top:10px;">2. 盈利能力</div>
                <div class="tableBox">
                    <table class="re_search_detail_table">
                        <thead>
                            <tr>
                                <td width="10%">指标</td>
                                <td width="10%">实际值</td>
                                <td width="10%">参考均值</td>
                                <td width="70%">指标评分</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr> 
                                <td width="10%">净资产收益率</td>
                                <td width="10%">{{isNaN(form.jlr/(form.zcze-form.fzze))?'0':((form.jlr/(form.zcze-form.fzze))*100).toFixed(2)}}%</td>
                                <td width="10%">3.3%</td>
                                <td width="70%"><m-slider :choseIndex="slider26" :setIndex="26"></m-slider></td>
                            </tr>
                            <tr> 
                                <td width="10%">营业利润率</td>
                                <td width="10%">{{isNaN(form.yylr/form.yysr)?'0':((form.yylr/form.yysr)*100).toFixed(2)}}%</td>
                                <td width="10%">4.0%</td>
                                <td width="70%"><m-slider :choseIndex="slider27" :setIndex="27"></m-slider></td>
                            </tr>
                            <tr> 
                                <td width="10%">资产周转率</td>
                                <td width="10%">{{isNaN(form.yysr/(form.zcze-form.qtysk-form.wxzc))?'0':((form.yysr/(form.zcze-form.qtysk-form.wxzc))*100).toFixed(2)}}%</td>
                                <td width="10%">100%</td>
                                <td width="70%"><m-slider :choseIndex="slider28" :setIndex="28"></m-slider></td>
                            </tr>
                            <tr> 
                                <td width="100%" colspan="4">
                                    <el-input
                                        type="textarea"
                                        :rows="4"
                                        placeholder="（请分析师对该指标信用评分进行解释说明）"
                                        v-model="textarea4">
                                    </el-input>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="M2-M3-jjhj-title" style="font-size:14px;margin-top:10px;">3. 经营效率</div>
                <div class="tableBox">
                    <table class="re_search_detail_table">
                        <thead>
                            <tr>
                                <td width="10%">指标</td>
                                <td width="10%">实际值</td>
                                <td width="10%">参考均值</td>
                                <td width="70%">指标评分</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr> 
                                <td width="10%">应收账款周转率</td>
                                <td width="10%">{{isNaN(form.yysr/form.yszk)?'0':((form.yysr/form.yszk)*100).toFixed(2)}}%</td>
                                <td width="10%">1720%</td>
                                <td width="70%"><m-slider :choseIndex="slider29" :setIndex="29"></m-slider></td>
                            </tr>
                            <tr> 
                                <td width="10%">现金收入比</td>
                                <td width="10%">{{isNaN(form.xsxjlr/form.yysr)?'0':((form.xsxjlr/form.yysr)*100).toFixed(2)}}%</td>
                                <td width="10%">100%</td>
                                <td width="70%"><m-slider :choseIndex="slider30" :setIndex="30"></m-slider></td>
                            </tr>
                             <tr> 
                                <td width="10%">资产负债率</td>
                                <td width="10%">{{isNaN(form.fzze/form.zcze)?'0':((form.fzze/form.zcze)*100).toFixed(2)}}%</td>
                                <td width="10%">58.6%</td>
                                <td width="70%"><m-slider :choseIndex="slider31" :setIndex="31"></m-slider></td>
                            </tr>
                            <tr> 
                                <td width="100%" colspan="4">
                                    <el-input
                                        type="textarea"
                                        :rows="4"
                                        placeholder="（请分析师对该指标信用评分进行解释说明）"
                                        v-model="textarea5">
                                    </el-input>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="M2-M3-jjhj-title" style="font-size:14px;margin-top:10px;">4.杠杆和偿还能力</div>
                <div class="tableBox">
                    <table class="re_search_detail_table">
                        <thead>
                            <tr>
                                <td width="10%">指标</td>
                                <td width="10%">实际值</td>
                                <td width="10%">参考均值</td>
                                <td width="70%">指标评分</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr> 
                                <td width="10%">现金流动负债比</td>
                                <td width="10%">{{isNaN(form.jyxjxjl/form.ldfz)?'0':((form.jyxjxjl/form.ldfz)*100).toFixed(2)}}%</td>
                                <td width="10%">14.1%</td>
                                <td width="70%"><m-slider :choseIndex="slider32" :setIndex="32"></m-slider></td>
                            </tr>
                            <tr> 
                                <td width="10%">速动比率</td>
                                <td width="10%">{{isNaN((form.ldzc-form.ch)/form.ldfz)?'0':(((form.ldzc-form.ch)/form.ldfz)*100).toFixed(2)}}%</td>
                                <td width="10%">60.1%</td>
                                <td width="70%"><m-slider :choseIndex="slider33" :setIndex="33"></m-slider></td>
                            </tr>
                            <tr> 
                                <td width="100%" colspan="4">
                                    <el-input
                                        type="textarea"
                                        :rows="4"
                                        placeholder="（请分析师对该指标信用评分进行解释说明）"
                                        v-model="textarea6">
                                    </el-input>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> 
        </div>
        <div class="M2-M3-jjhj-buttonList">
            <el-button type="primary" size="small" @click="save">保存</el-button>
            <el-button size="small" @click="routerTo('M2toM3YWYY')" icon="el-icon-arrow-left">上一页</el-button>
        </div>
    </div>
</template>
<script>
import MSlider from '../component/M2-slider'
export default { 
    name:'M2toM3HYHJ',
    components:{MSlider},
    data() {
      return {
        tableData: [{
            fl:"主营业务",
            sy:"中药生产及药品贸易",
            code:"",
            c27:"",
        }],
         tableData1: [{
            sn:"1",
            date:"22:10",
            title:"每日提醒--原油（12月2日）",
            from:"生意社",
        },{
            sn:"2",
            date:"11:10",
            title:"每日提醒--原油（11月22日）",
            from:"生意社",
        },{
            sn:"3",
            date:"23:10",
            title:"每日提醒--原油（10月2日）",
            from:"生意社",
        },{
            sn:"4",
            date:"14:10",
            title:"每日提醒--原油（11月2日）",
            from:"生意社",
        }],
        textarea:'',
        textarea2:'',
        textarea3:'',
        textarea4:'',
        textarea5:'',
        textarea6:'',

        slider23:'',
        slider24:'',
        slider25:'',
        slider26:'',
        slider27:'',
        slider28:'',
        slider29:'',
        slider30:'',
        slider31:'',
        slider32:'',
        slider33:'', 

        form:{}
      } 
    },
    // watch:{ 
    //     $route(to,from){
    //         for(let i in this.routeTags){ 
    //             if(this.routeTags[i].meta.name == from.meta.name){
    //                 this.$store.commit("delRouteTags", i); 
    //             }
    //         }
    //     } 
    // },  
    mounted(){
        var entName = localStorage.getItem('dupontEntName')
        var entData = JSON.parse(localStorage.getItem('dupontEntData'+entName))
 
        this.form = JSON.parse(entData.m1JsonData)
        var arr = entData.m2point?entData.m2point:[]
        this.slider23 = arr[23]?arr[23]:entData.M1Point3
        this.slider24 = arr[24]?arr[24]:entData.M1Point3 
        this.slider25 = arr[25]?arr[25]:entData.M1Point3
        this.slider26 = arr[26]?arr[26]:entData.M1Point3
        this.slider27 = arr[27]?arr[27]:entData.M1Point3
        this.slider28 = arr[28]?arr[28]:entData.M1Point3
        this.slider29 = arr[29]?arr[29]:entData.M1Point3
        this.slider30 = arr[30]?arr[30]:entData.M1Point3
        this.slider31 = arr[31]?arr[31]:entData.M1Point3
        this.slider32 = arr[32]?arr[32]:entData.M1Point3
        this.slider33 = arr[33]?arr[33]:entData.M1Point3
       
        this.textarea=entData.cwzbText1
        this.textarea2=entData.cwzbText2
        this.textarea3=entData.cwzbText3
        this.textarea4=entData.cwzbText4
        this.textarea5=entData.cwzbText5
        this.textarea6=entData.cwzbText6
    },
    methods:{
        save(){
            var entName = localStorage.getItem('dupontEntName')
            var entData = JSON.parse(localStorage.getItem('dupontEntData'+entName))

            this.$message({
                message: this.$store.state.saveText,
                type: 'success'
            });

            entData.m2point = entData.m2point?entData.m2point:[]
            entData.m2point[23] = entData.m2point[23]?entData.m2point[23]:entData.M1Point3
            entData.m2point[24] = entData.m2point[24]?entData.m2point[24]:entData.M1Point3
            entData.m2point[25] = entData.m2point[25]?entData.m2point[25]:entData.M1Point3
            entData.m2point[26] = entData.m2point[26]?entData.m2point[26]:entData.M1Point3
            entData.m2point[27] = entData.m2point[27]?entData.m2point[27]:entData.M1Point3
            entData.m2point[28] = entData.m2point[28]?entData.m2point[28]:entData.M1Point3
            entData.m2point[29] = entData.m2point[29]?entData.m2point[29]:entData.M1Point3
            entData.m2point[30] = entData.m2point[30]?entData.m2point[30]:entData.M1Point3
            entData.m2point[31] = entData.m2point[31]?entData.m2point[31]:entData.M1Point3
            entData.m2point[32] = entData.m2point[32]?entData.m2point[32]:entData.M1Point3
            entData.m2point[33] = entData.m2point[33]?entData.m2point[33]:entData.M1Point3

            entData.cwzbText1 = this.textarea
            entData.cwzbText2 = this.textarea2
            entData.cwzbText3 = this.textarea3
            entData.cwzbText4 = this.textarea4
            entData.cwzbText5 = this.textarea5
            entData.cwzbText6 = this.textarea6

            localStorage.setItem('dupontEntData'+entName,JSON.stringify(entData))
            // localStorage.setItem('cwzbText1',this.textarea)
            // localStorage.setItem('cwzbText2',this.textarea2)
            // localStorage.setItem('cwzbText3',this.textarea3)
            // localStorage.setItem('cwzbText4',this.textarea4)
            // localStorage.setItem('cwzbText5',this.textarea5)
            // localStorage.setItem('cwzbText6',this.textarea6)
        },
        routerTo(name){ 
            this.save()
            this.$router.push({name:name})
        }
    }
}
</script>
<style lang="less" scoped>
@import url("../../../assets/css/table.css");
@media screen and (max-width: 1300px) {
    .M2-M3-content{
        height: 100%;
    } 
    .M2-M3-jjhj-chart,.M2-M3-jjhj-Edit{
        height: calc(~"100%");
    }
 
}
@media screen and (min-width: 1300px) {
    .M2-M3-content{
         height: calc(~"100vh - 236px");
     }
    .M2-M3-jjhj-chart,.M2-M3-jjhj-Edit{
        overflow-x: hidden;
    //   height: calc(~"100vh - 176px");
    } 
}
.M2-M3-jjhj{
    width: 100%;
    // flex-wrap: wrap;
    >.M2-M3-content{
        // height: calc(~"100vh - 236px");
        display: flex;
        justify-content: space-between;
    }
    input{
        border: none;
        outline: none;
    }
    .tableBox{
        width: 100%;
        overflow-x: auto;
    }
    .M2-M3-jjhj-title{
        font-size: 16px;
        font-weight: bold;
        line-height: 27px;
        color: #30343D;
    }
    .M2-M3-jjhj-chart{
        width: 30%;
        margin-right: 20px;
        height: 100%;
        overflow-x: hidden;
        >#zx-chart{
            margin-top:20px;
            width: 100%; 
            height: 615px;
        }
    }
    .M2-M3-jjhj-Edit{ 
        width: 70%;
        height: 100%;
        overflow-x: hidden;
    }
    .M2-M3-jjhj-buttonList{
        display: flex;
        margin-top: 20px;
        justify-content: flex-end;
    }
}
</style>